import {
  changePluginStatusMut,
  changeVendorStatusMut,
  configurePluginMut,
  getConfiguredPluginQuery,
  getPluginQuery,
  getVendorQuery,
  listAllAndConfiguredPluginsQuery,
  listAllPluginsByLasEvaluatedPluginQuery,
  listAllPluginsQuery,
  listConfiguredPluginsByLasEvaluatedPluginQuery,
  listConfiguredPluginsQuery,
  listPluginRunsByLastEvaluatedQuery,
  listPluginRunsQuery,
  listPluginsByLastEvaulatedQuery,
  listPluginsQuery,
  listVendorsQuery,
  listVendorsQueryByLastEvaluatedVendor,
  registerPluginMut,
  registerVendorMut,
  startPluginRunMut,
  updateConfiguredPluginMut,
  updatePluginMut,
} from "../graphql/registrar";
import { sendMutationRequest, sendQueryRequest } from "./apollo-client";
export const getVendor = (variables) => {
  return sendQueryRequest({
    query: getVendorQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const registerVendor = (variables) => {
  return sendMutationRequest({
    mutation: registerVendorMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listPlugins = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated_plugin
      ? listPluginsByLastEvaulatedQuery
      : listPluginsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const registerPlugin = (variables) => {
  return sendMutationRequest({
    mutation: registerPluginMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listAllPlugins = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated_plugin
      ? listAllPluginsByLasEvaluatedPluginQuery
      : listAllPluginsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listConfiguredPlugins = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated
      ? listConfiguredPluginsByLasEvaluatedPluginQuery
      : listConfiguredPluginsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const getPlugin = (variables) => {
  return sendQueryRequest({
    query: getPluginQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const getConfiguredPlugin = (variables) => {
  return sendQueryRequest({
    query: getConfiguredPluginQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const configurePlugin = (variables) => {
  return sendMutationRequest({
    mutation: configurePluginMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const updateConfiguredPlugin = (variables) => {
  return sendMutationRequest({
    mutation: updateConfiguredPluginMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const updatePlugin = (variables) => {
  return sendMutationRequest({
    mutation: updatePluginMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listVendors = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated_vendor
      ? listVendorsQueryByLastEvaluatedVendor
      : listVendorsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const startPlugin = (variables) => {
  return sendMutationRequest({
    mutation: startPluginRunMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listPluginRuns = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated
      ? listPluginRunsByLastEvaluatedQuery
      : listPluginRunsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const changePluginStatus = (variables) => {
  return sendMutationRequest({
    mutation: changePluginStatusMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const changeVendorStatus = (variables) => {
  return sendMutationRequest({
    mutation: changeVendorStatusMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listAllAndConfiguredPlugins = (variables) => {
  return sendQueryRequest({
    query: variables?.last_evaluated
      ? listPluginRunsByLastEvaluatedQuery
      : listAllAndConfiguredPluginsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};
