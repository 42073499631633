import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  concat,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { navigateToUrl } from "single-spa";
import {
  SESSION_EXPIRED,
  TOKEN_EXPIRED,
  UNAUTHENTICATED,
} from "../constants/grpc-code";
import {
  removeToken,
  getAccessToken,
  getState,
  removeState,
  signOut,
} from "@defense-station/auth";
import { getIpAddress } from "./auth-service";

const logoutLink = onError(({ networkError }) => {
  // if (networkError.statusCode === 401){
  //   removeToken();
  //   navigateToUrl('/login')
  // }
});

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_SERVER,
});

const handleAuthenticationError = (response) => {
  if (response?.errors) {
    const error = response.errors[0]?.message;
    // const messageArray = error.split(" ");
    if (
      error == UNAUTHENTICATED ||
      error == TOKEN_EXPIRED ||
      error == SESSION_EXPIRED
    ) {
      removeToken().then(() => {
        removeState();
        signOut().then(() => navigateToUrl("/login"));
      });
    }
    // if(messageArray[0] == ACCESS_DENIED){
    //   removeToken();
    //   navigateToUrl("/login")
    // }
  }
  return response;
};

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const token = getAccessToken();
  const state = getState();
  let headers = {};
  if (token && !operation?.variables?.skipAuth) {
    headers = { ...headers, authorization: "Bearer " + token };
  }
  if (state) {
    headers = { ...headers, state: state };
  }
  operation.setContext(({ headers: defaultHeaders = {} }) => ({
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  }));
  if(operation?.variables?.disableErrorHandling){
    return forward(operation)
  }
  return forward(operation).map(handleAuthenticationError);
});

const authMiddlewareWithIP = new ApolloLink(async (operation, forward) => {
  const ip = await getIpAddress();
  const token = getAccessToken();
  const state = getState();
  let headers = {
    "client-ip": ip,
  };
  if (token && !operation?.variables?.skipAuth) {
    headers = { ...headers, authorization: "Bearer " + token };
  }
  if (state) {
    headers = { ...headers, state: state };
  }
  operation.setContext(({ defaultHeaders = {} }) => ({
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  }));
  if(operation?.variables?.disableErrorHandling){
    return forward(operation)
  }
  return forward(operation).map(handleAuthenticationError);
});


export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export const clientWithoutErrorHandling = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export const clientWithIP = new ApolloClient({
  link: concat(authMiddlewareWithIP, httpLink),
  cache: new InMemoryCache(),
});

export const clientWithIPWithoutErrorHandling = new ApolloClient({
  link: concat(authMiddlewareWithIP, httpLink),
  cache: new InMemoryCache(),
});

export const sendMutationRequest = (parameters) => {
  return client.mutate(parameters);
};

export const sendMutationRequestWithIP = (parameters) => {
  return clientWithIP.mutate(parameters);
};

export const sendQueryRequestWithIP = (parameters) => {
  return clientWithIP.query(parameters);
};

export const sendQueryRequest = (parameters) => {
  return client.query(parameters);
};
