import { gql } from "@apollo/client";

export const createAssetMut = gql`
  mutation MyMutation(
    $username: String = ""
    $tags: [String] = ""
    $props: String = ""
    $asset_id: String = ""
    $category_name: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateAsset(
      input: {
        account_id: $account_id
        category_name: $category_name
        asset_id: $asset_id
        tags: $tags
        props: $props
        username: $username
      }
    ) {
      asset {
        id
        props
        tags
      }
    }
  }
`;

export const updateAssetMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $add_tags: [String] = ""
    $remove_tags: [String] = ""
    $username: String = ""
    $asset_id: String = ""
    $update_props: String = ""
  ) {
    asset_intelligence_v1_UpdateAssets(
      input: {
        account_id: $account_id
        add_tags: $add_tags
        remove_tags: $remove_tags
        username: $username
        asset_id: $asset_id
        update_props: $update_props
      }
    )
  }
`;

export const getAssetQuery = gql`
  query GetAsset(
    $account_id: String = ""
    $username: String = ""
    $asset_id: String = ""
    $include_relations: Boolean = false
  ) {
    asset_intelligence_v1_GetAsset(
      input: {
        account_id: $account_id
        username: $username
        asset_id: $asset_id
        include_relations: $include_relations
      }
    ) {
      assets {
        id
        props
        tags
      }
      end_assets {
        id
        props
        tags
      }
      relations {
        end_asset_id
        props
        start_asset_id
        type
      }
    }
  }
`;

export const deleteAssetsMut = gql`
  mutation DeleteAsset(
    $account_id: String = ""
    $username: String = ""
    $asset_id: String = ""
    $where: api_asset_intelligence_v1_WhereClause_Input = {}
    $tags: [String] = ""
  ) {
    asset_intelligence_v1_DeleteAssets(
      input: {
        account_id: $account_id
        asset_id: $asset_id
        username: $username
        where: $where
        tags: $tags
      }
    )
  }
`;

export const listAssetsAndRelationsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
  ) {
    asset_intelligence_v1_ListAssets(
      input: {
        account_id: $account_id
        page_number: $page_number
        page_size: $page_size
        username: $username
      }
    ) {
      assets {
        id
        props
        tags
      }
    }
  }
`;

export const exploreAssetsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $page_size: Int = 50
    $page_number: Int = 1
    $start_asset_id: String = ""
    $start_asset_tags: [String] = ""
  ) {
    asset_intelligence_v1_ListRelations(
      input: {
        account_id: $account_id
        page_number: $page_number
        page_size: $page_size
        username: $username
        start_asset_id: $start_asset_id
        start_asset_tags: $start_asset_tags
      }
    ) {
      assets {
        id
        props
        tags
      }
      relations {
        end_asset_id
        props
        start_asset_id
        type
      }
    }
  }
`;

export const getRelationQuery = gql`
  query MyQuery(
    $username: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    assetinventory_v1_GetRelation(
      input: { account_id: $account_id, id: $id, username: $username }
    ) {
      relation {
        end_id
        id
        props
        start_id
        type
      }
    }
  }
`;

export const createRelationMut = gql`
  mutation MyMutation(
    $end_asset_id: String = ""
    $account_id: String = ""
    $start_asset_id: String = ""
    $type: String = ""
    $username: String = ""
    $props: String = ""
  ) {
    asset_intelligence_v1_CreateRelation(
      input: {
        account_id: $account_id
        type: $type
        props: $props
        start_asset_id: $start_asset_id
        end_asset_id: $end_asset_id
        username: $username
      }
    ) {
      relation {
        end_asset_id
        props
        start_asset_id
        type
      }
    }
  }
`;

export const updateRelationMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $id: String = ""
    $props: JSON = ""
    $type: String = ""
    $username: String = ""
  ) {
    assetinventory_v1_UpdateRelation(
      input: {
        account_id: $account_id
        id: $id
        type: $type
        props: $props
        username: $username
      }
    )
  }
`;

export const deleteRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $type: String = ""
    $props: JSON = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    assetinventory_v1_DeleteRelation(
      input: {
        account_id: $account_id
        id: $id
        props: $props
        type: $type
        username: $username
      }
    )
  }
`;

export const askMut = gql`
  mutation MyMutation2(
    $account_id: String = ""
    $question: String = ""
    $username: String = ""
  ) {
    assetinventory_v1_Ask(
      input: {
        account_id: $account_id
        username: $username
        question: $question
      }
    ) {
      answer
    }
  }
`;

export const askAssetQuestionMut = gql`
  query MyQuery(
    $username: String = ""
    $question: String = ""
    $props: JSON = ""
    $labels: [String] = ""
    $account_id: String = ""
  ) {
    assetinventory_v1_ListAssets(
      input: {
        account_id: $account_id
        props: $props
        question: $question
        username: $username
        labels: $labels
      }
    ) {
      answer
    }
  }
`;

export const listAssetsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $tags: [String] = ""
    $where: api_asset_intelligence_v1_WhereClause_Input = {}
    $page_size: Int = 10
    $page_number: Int = 1
  ) {
    asset_intelligence_v1_ListAssets(
      input: {
        account_id: $account_id
        username: $username
        page_size: $page_size
        page_number: $page_number
        tags: $tags
        where: $where
      }
    ) {
      assets {
        id
        props
        tags
      }
    }
  }
`;

export const createLifecycleMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $description: String = ""
    $name: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_CreateLifecycle(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        username: $username
      }
    ) {
      lifecycle {
        created_at
        created_by
        description
        name
        updated_at
        updated_by
      }
    }
  }
`;

export const deleteLifecycleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteLifecycle(
      input: { account_id: $account_id, name: $name, username: $username }
    )
  }
`;

export const updateLifecycleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateLifecycle(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        username: $username
      }
    )
  }
`;

export const getLifecycleQuery = gql`
  query MyQuery(
    $username: String = ""
    $name: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_GetLifecycle(
      input: { account_id: $account_id, name: $name, username: $username }
    ) {
      lifecycle {
        created_at
        created_by
        description
        name
        updated_at
        updated_by
      }
    }
  }
`;

export const listLifecyclesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $name: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_ListLifecycles(
      input: { account_id: $account_id, name: $name, username: $username }
    ) {
      lifecycles {
        description
        name
      }
    }
  }
`;

export const createStageWithNextStageMut = gql`
  mutation MyMutation(
    $relations: [api_asset_intelligence_v1_StageRelation_Input] = {}
    $username: String = ""
    $name: String = ""
    $lifecycle_name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateStage(
      input: {
        account_id: $account_id
        description: $description
        lifecycle_name: $lifecycle_name
        name: $name
        relations: $relations
        username: $username
      }
    ) {
      stage {
        id
        created_at
        description
        lifecycle_name
        name
        updated_at
        updated_by
        relations {
          description
          end_stage_name
          type
        }
      }
    }
  }
`;

export const createStageMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $conditions: [api_asset_intelligence_v1_Condition_Input] = {}
    $description: String = ""
    $lifecycle_name: String = ""
    $name: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_CreateStage(
      input: {
        account_id: $account_id
        conditions: $conditions
        description: $description
        lifecycle_name: $lifecycle_name
        name: $name
        username: $username
      }
    ) {
      stage {
        conditions {
          actions {
            change_state_name
            publish_event
          }
          behavior
          evaluation_query
          type
        }
        id
        created_at
        description
        lifecycle_name
        name
        updated_at
        updated_by
      }
    }
  }
`;

export const createStageRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_start_id: String = ""
    $stage_end_id: String = ""
    $lifecycle_name: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateStageRelation(
      input: {
        account_id: $account_id
        lifecycle_name: $lifecycle_name
        stage_end_id: $stage_end_id
        stage_start_id: $stage_start_id
        username: $username
      }
    )
  }
`;

export const updateStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $lifecycle_name: String = ""
    $id: String = ""
    $description: String = ""
    $conditions: [api_asset_intelligence_v1_Condition_Input] = {}
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateStage(
      input: {
        account_id: $account_id
        description: $description
        id: $id
        lifecycle_name: $lifecycle_name
        name: $name
        username: $username
        conditions: $conditions
      }
    )
  }
`;

export const deleteStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $lifecycle_name: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteStage(
      input: {
        account_id: $account_id
        id: $id
        lifecycle_name: $lifecycle_name
        username: $username
      }
    )
  }
`;

export const deleteStageRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_start_id: String = ""
    $stage_end_id: String = ""
    $lifecycle_name: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteStageRelation(
      input: {
        lifecycle_name: $lifecycle_name
        account_id: $account_id
        stage_end_id: $stage_end_id
        stage_start_id: $stage_start_id
        username: $username
      }
    )
  }
`;

export const listStagesQuery = gql`
  query MyQuery(
    $lifecycle_name: String = ""
    $account_id: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_ListStages(
      input: {
        account_id: $account_id
        lifecycle_name: $lifecycle_name
        username: $username
      }
    ) {
      relations {
        end_id
        start_id
      }
      stages {
        conditions {
          actions {
            change_state_name
            publish_event
          }
          behavior
          evaluation_query
          type
        }
        created_at
        description
        id
        lifecycle_name
        name
        updated_at
        updated_by
      }
    }
  }
`;

export const addAssetToStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_name: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_AddAssetToStage(
      input: {
        account_id: $account_id
        id: $id
        lifecycle_name: $lifecycle_name
        stage_id: $stage_id
        username: $username
      }
    )
  }
`;

export const removeAssetFromStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_name: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_RemoveAssetFromStage(
      input: {
        account_id: $account_id
        id: $id
        stage_id: $stage_id
        lifecycle_name: $lifecycle_name
        username: $username
      }
    )
  }
`;

export const createCategoriesMut = gql`
  mutation MyMutation(
    $relations: api_asset_intelligence_v1_CategoryRelation_Input = {}
    $username: String = ""
    $ui_schema: String = ""
    $schema: String = ""
    $name: String = ""
    $icon_size: BigInt = 0
    $description: String = ""
    $default_tags: [String] = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateCategory(
      input: {
        account_id: $account_id
        default_tags: $default_tags
        description: $description
        icon_size: $icon_size
        name: $name
        relations: $relations
        schema: $schema
        ui_schema: $ui_schema
        username: $username
      }
    ) {
      category {
        category_id
        created_at
        default_tags
        description
        ds_managed
        icon
        name
        relations {
          match_props
          match_tags
          schema
          type
          ui_schema
        }
        schema
        ui_schema
        updated_at
      }
      signed_url
    }
  }
`;

export const getCategoryQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $category_id: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_GetCategory(
      input: {
        account_id: $account_id
        category_id: $category_id
        username: $username
      }
    ) {
      category {
        category_id
        created_at
        default_tags
        description
        icon
        name
        relations {
          match_props
          match_tags
          schema
          type
          ui_schema
        }
        schema
        ui_schema
        updated_at
      }
    }
  }
`;

export const deleteCategoryMut = gql`
  mutation MyMutation(
    $username: String = ""
    $category_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteCategory(
      input: {
        account_id: $account_id
        category_id: $category_id
        username: $username
      }
    )
  }
`;

export const updateCategoryMut = gql`
  mutation MyMutation(
    $relations: api_asset_intelligence_v1_CategoryRelation_Input = {
      match_props: ""
      match_tags: ""
      schema: ""
      type: ""
      ui_schema: ""
    }
    $username: String = ""
    $ui_schema: String = ""
    $schema: String = ""
    $name: String = ""
    $icon: String = ""
    $description: String = ""
    $default_tags: [String] = ""
    $category_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateCategory(
      input: {
        category_id: $category_id
        default_tags: $default_tags
        description: $description
        icon: $icon
        name: $name
        relations: $relations
        schema: $schema
        ui_schema: $ui_schema
        username: $username
        account_id: $account_id
      }
    )
  }
`;

export const listCategoriesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $ds_managed: Boolean = false
    $username: String = ""
  ) {
    asset_intelligence_v1_ListCategories(
      input: {
        username: $username
        ds_managed: $ds_managed
        account_id: $account_id
      }
    ) {
      categories {
        category_id
        created_at
        default_tags
        description
        icon
        name
        relations {
          match_props
          match_tags
          schema
          type
          ui_schema
        }
        schema
        ui_schema
        updated_at
      }
    }
  }
`;

export const updateAssetPropMut = gql`
  mutation MyMutation(
    $asset_id: String = ""
    $account_id: String = ""
    $username: String = ""
    $update_props: String = ""
  ) {
    asset_intelligence_v1_UpdateAssets(
      input: {
        asset_id: $asset_id
        account_id: $account_id
        username: $username
        update_props: $update_props
      }
    )
  }
`;
