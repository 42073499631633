import {
  listPoliciesQuery,
  listPoliciesNextQuery,
  getQuestionnaireQuery,
  submitQuestionnaireMut,
  deletePolicyMut,
  createDocumentMut,
  updatePolicyMut,
  getPolicyQuery,
  createScopeMut,
  updateScopeMut,
  deleteScopeMut,
  getScopeQuery,
  listRolesQuery,
  listRolesByLastEvaluatedRoleQuery,
  createRoleMut,
  updateRoleMut,
  deleteRoleMut,
  listStatementsByLastEvaluatedStatementQuery,
  listStatementsQuery,
  createStatementDraftMut,
  publishStatementMut,
  getStatementQuery,
  getStatementDraftQuery,
  updateStatementDraftMut,
  addControlToDocumentMut,
  addScopeToPolicyMut,
  addRoleToPolicyMut,
  removeControlFromDocumentMut,
  removeScopeFromPolicyMut,
  removeRoleFromPolicyMut,
  listPolicyRolesQuery,
  listPolicyRolesByLastEvaluatedRoleQuery,
  listPolicyScopesQuery,
  listPolicyScopesByLastEvaluatedScopeQuery,
  listPolicyStatementsQuery,
  listPolicyStatementsByLastEvaluatedStatementQuery,
  getRoleQuery,
  listScopesQuery,
  listScopesByLastEvaluatedScopeQuery,
  listDocumentsQuery,
  listControlsQuery,
  getUsageQuery,
  getDocumentQuery,
  listDocumentControlsQuery,
  listDocumentRolesQuery,
  listDocumentSupervisorsQuery,
  updateDocumentMut,
  createControlMut,
  updateControlDraftMut,
  sendForReviewMut,
  addSupervisorsMut,
  sendForApprovalMut,
  createPersonnalMut,
  listPesonnalQuery,
  getPersonnelQuery,
  deletePersonnalMut,
  updatePersonnalMut,
  publishDocumentMut,
  deleteDocumentMut,
  updateDocumentLifecycleMut,
  getControlQuery,
  getDocumentLifecycleDetailsQuery,
  approveDocumentMut,
  reviewDocumentMut,
  publishControlMut,
  getDocumentDraftMut,
  removeDocumentSupervisorsMut,
  listPersonnelAttachementsQuery,
  deleteControlMut,
  createRoleRelationMut,
  deleteRoleRelationMut,
  addScopeTagToDocumentMut,
  removeScopeTagFromDocumentMut,
  listNonCompliantUsersQuery,
  createTemplateMut,
  updateTemplateMut,
  deleteTemplateMut,
  listTemplatesQuery,
  getTemplateQuery,
  generateDocumentsMut,
  getGeneratePolicyStatusQuery,
  getCompanyQuestionnaireQuery,
  addTagsMut,
  removeTagsMut,
  getSettingsQuery,
  updateSettingsMut,
  updateDocumentClassificationMut,
  addManagerToPersonnelMut,
  removeManagerFromPersonnelMut,
  listTemplatesByLastEvaluatedTemplatedQuery,
  addRoleToPersonnelMut,
  removeRoleFromPersonnelMut,
  listPersonnelRolesAndManagersQuery,
  removeRoleFromDocumentMut,
  addRoleToDocumentMut,
  optInMut,
  optOutMut,
  addDocumentToAIMut,
  removeDocumentFromAIMut,
  listPublishedDocumentsQuery,
  listPersonnelDocumentsQuery,
  listAssessmentsQuery,
  listAssessmentQueryByLastEvaluatedAssessment,
  listAssessmentQuestionsQuery,
  listAssessmentQuestionsQueryByLastEvaluatedAssessmentQuestion,
  getAssessmentQuery,
  createAssessmentMut,
  updateAssessmentMut,
  deleteAssessmentMut,
  addQuestionsToAssessmentMut,
  removeQuestionFromAssessmentMut,
  getEmployeeDashboardDataQuery,
  generateRolesMut,
  acknowledgeDocumentMut,
} from "../graphql/policy";
import { sendMutationRequest, sendQueryRequest } from "./apollo-client";
import { getAccessToken } from "@defense-station/auth";

export const listPolicies = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_policy
      ? listPoliciesNextQuery
      : listPoliciesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getQuestionnaire = (variables) =>
  sendQueryRequest({
    query: getQuestionnaireQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getCompanyQuestionnaire = (variables) =>
  sendQueryRequest({
    query: getCompanyQuestionnaireQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const submitQuestionnaire = (variables) =>
  sendMutationRequest({
    mutation: submitQuestionnaireMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deletePolicy = (variables) =>
  sendMutationRequest({
    mutation: deletePolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createDocument = (variables) =>
  sendMutationRequest({
    mutation: createDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updatePolicy = (variables) =>
  sendMutationRequest({
    mutation: updatePolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getPolicy = (variables) =>
  sendQueryRequest({
    query: getPolicyQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createScope = (variables) =>
  sendMutationRequest({
    mutation: createScopeMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateScope = (variables) =>
  sendMutationRequest({
    mutation: updateScopeMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteScope = (variables) =>
  sendMutationRequest({
    mutation: deleteScopeMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getScope = (variables) =>
  sendQueryRequest({
    query: getScopeQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listRoles = (variables) =>
  sendQueryRequest({
    query: listRolesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createRole = (variables) =>
  sendMutationRequest({
    mutation: createRoleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateRole = (variables) =>
  sendMutationRequest({
    mutation: updateRoleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteRole = (variables) =>
  sendMutationRequest({
    mutation: deleteRoleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createRoleRelation = (variables) =>
  sendMutationRequest({
    mutation: createRoleRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteRoleRelation = (variables) =>
  sendMutationRequest({
    mutation: deleteRoleRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listStatements = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_statement
      ? listStatementsByLastEvaluatedStatementQuery
      : listStatementsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createStatementDraft = (variables) =>
  sendMutationRequest({
    mutation: createStatementDraftMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const publishStatement = (variables) =>
  sendMutationRequest({
    mutation: publishStatementMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getStatement = (variables) =>
  sendQueryRequest({
    query: getStatementQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getStatementDraft = (variables) =>
  sendQueryRequest({
    query: getStatementDraftQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateStatementDraft = (variables) =>
  sendMutationRequest({
    mutation: updateStatementDraftMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addControlToDocument = (variables) =>
  sendMutationRequest({
    mutation: addControlToDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addScopeToPolicy = (variables) =>
  sendMutationRequest({
    mutation: addScopeToPolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addRoleToPolicy = (variables) =>
  sendMutationRequest({
    mutation: addRoleToPolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeControlFromDocument = (variables) =>
  sendMutationRequest({
    mutation: removeControlFromDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeScopeFromPolicy = (variables) =>
  sendMutationRequest({
    mutation: removeScopeFromPolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeRoleFromPolicy = (variables) =>
  sendMutationRequest({
    mutation: removeRoleFromPolicyMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPolicyRoles = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_role
      ? listPolicyRolesByLastEvaluatedRoleQuery
      : listPolicyRolesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPolicyScopes = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_scope
      ? listPolicyScopesQuery
      : listPolicyScopesByLastEvaluatedScopeQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPolicyStatements = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_statement
      ? listPolicyStatementsQuery
      : listPolicyStatementsByLastEvaluatedStatementQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getRole = (variables) =>
  sendQueryRequest({
    query: getRoleQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listScopes = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_scope
      ? listScopesByLastEvaluatedScopeQuery
      : listScopesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listDocuments = (variables) =>
  sendQueryRequest({
    query: listDocumentsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listControls = (variables) =>
  sendQueryRequest({
    query: listControlsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getUsage = (variables) =>
  sendQueryRequest({
    query: getUsageQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getDocument = (variables) =>
  sendQueryRequest({
    query: getDocumentQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listDocumentControls = (variables) =>
  sendQueryRequest({
    query: listDocumentControlsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listDocumentRoles = (variables) =>
  sendQueryRequest({
    query: listDocumentRolesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listDocumentSupervisors = (variables) =>
  sendQueryRequest({
    query: listDocumentSupervisorsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateDocument = (variables) =>
  sendMutationRequest({
    mutation: updateDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createControl = (variables) =>
  sendMutationRequest({
    mutation: createControlMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateControlDraft = (variables) =>
  sendMutationRequest({
    mutation: updateControlDraftMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const sendForReview = (variables) =>
  sendMutationRequest({
    mutation: sendForReviewMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addSupervisors = (variables) =>
  sendMutationRequest({
    mutation: addSupervisorsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const sendForApproval = (variables) =>
  sendMutationRequest({
    mutation: sendForApprovalMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createPersonnal = (variables) =>
  sendMutationRequest({
    mutation: createPersonnalMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPersonnals = (variables) =>
  sendQueryRequest({
    query: listPesonnalQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getPersonnel = (variables) =>
  sendQueryRequest({
    query: getPersonnelQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deletePersonnal = (variables) =>
  sendMutationRequest({
    mutation: deletePersonnalMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updatePersonnal = (variables) =>
  sendMutationRequest({
    mutation: updatePersonnalMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const publishDocument = (variables) =>
  sendMutationRequest({
    mutation: publishDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteDocument = (variables) =>
  sendMutationRequest({
    mutation: deleteDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateDocumentLifecycle = (variables) =>
  sendMutationRequest({
    mutation: updateDocumentLifecycleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getControl = (variables) =>
  sendQueryRequest({
    query: getControlQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateControl = (variables) =>
  sendMutationRequest({
    mutation: updateControlDraftMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getDocumentLifecycleDetails = (variables) =>
  sendQueryRequest({
    query: getDocumentLifecycleDetailsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const approveDocument = (variables) =>
  sendMutationRequest({
    mutation: approveDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const reviewDocument = (variables) =>
  sendMutationRequest({
    mutation: reviewDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const publishControl = (variables) =>
  sendMutationRequest({
    mutation: publishControlMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getDocumentDraft = (variables) =>
  sendQueryRequest({
    query: getDocumentDraftMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeSupervisors = (variables) =>
  sendMutationRequest({
    mutation: removeDocumentSupervisorsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPersonnelAttachements = (variables) =>
  sendQueryRequest({
    query: listPersonnelAttachementsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteControl = (variables) =>
  sendMutationRequest({
    mutation: deleteControlMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addScopeTagToDocument = (variables) =>
  sendMutationRequest({
    mutation: addScopeTagToDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeScopeTagFromDocument = (variables) =>
  sendMutationRequest({
    mutation: removeScopeTagFromDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listNonCompliantUsers = (variables) =>
  sendQueryRequest({
    query: listNonCompliantUsersQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createPolicyTemplate = (variables) =>
  sendMutationRequest({
    mutation: createTemplateMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updatePolicyTemplate = (variables) =>
  sendMutationRequest({
    mutation: updateTemplateMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deletePolicyTemplate = (variables) =>
  sendMutationRequest({
    mutation: deleteTemplateMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPolicyTemplates = (variables) =>
  sendQueryRequest({
    query: listTemplatesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPolicyTemplatesByLastEvaluated = (variables) =>
  sendQueryRequest({
    query: listTemplatesByLastEvaluatedTemplatedQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getPolicyTemplate = (variables) =>
  sendQueryRequest({
    query: getTemplateQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const generateDocuments = (variables) =>
  sendMutationRequest({
    mutation: generateDocumentsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getGeneratePolicyStatus = (variables) =>
  sendQueryRequest({
    query: getGeneratePolicyStatusQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addTags = (variables) =>
  sendMutationRequest({
    mutation: addTagsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeTags = (variables) =>
  sendMutationRequest({
    mutation: removeTagsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getSettings = (variables) =>
  sendQueryRequest({
    query: getSettingsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateSettings = (variables) =>
  sendMutationRequest({
    mutation: updateSettingsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateDocumentClassifciation = (variables) =>
  sendMutationRequest({
    mutation: updateDocumentClassificationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addManagerToPersonnel = (variables) =>
  sendMutationRequest({
    mutation: addManagerToPersonnelMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeManagerFromPersonnel = (variables) =>
  sendMutationRequest({
    mutation: removeManagerFromPersonnelMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addRoleToPersonnel = (variables) =>
  sendMutationRequest({
    mutation: addRoleToPersonnelMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeRoleFromPersonnel = (variables) =>
  sendMutationRequest({
    mutation: removeRoleFromPersonnelMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPersonnelRolesAndMangers = (variables) =>
  sendQueryRequest({
    query: listPersonnelRolesAndManagersQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeRoleFromDocument = (variables) =>
  sendMutationRequest({
    mutation: removeRoleFromDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addRoleToDocument = (variables) =>
  sendMutationRequest({
    mutation: addRoleToDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const polictOptIn = (variables) =>
  sendMutationRequest({
    mutation: optInMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const policyOptOut = (variables) =>
  sendMutationRequest({
    mutation: optOutMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addDocumentToAI = (variables) =>
  sendMutationRequest({
    mutation: addDocumentToAIMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeDocumentFromAI = (variables) =>
  sendMutationRequest({
    mutation: removeDocumentFromAIMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const askPolicyStream = (variables) => {
  const token = getAccessToken();
  const url = new URL(process.env.GRAPHQL_SERVER);
  return fetch(url.origin + "/policy-manager/ai/v1/ask", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(variables),
  });
};

export const listPublishedDocuments = (variables) =>
  sendQueryRequest({
    query: listPublishedDocumentsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listPersonnelDocuments = (variables) =>
  sendQueryRequest({
    query: listPersonnelDocumentsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getRelevantDocuments = (variables) => {
  const token = getAccessToken();
  const url = new URL(process.env.GRAPHQL_SERVER);
  return fetch(url.origin + "/policy-manager/ai/v1/relevant-documents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(variables),
  });
};

export const listAssessments = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_assessment
      ? listAssessmentQueryByLastEvaluatedAssessment
      : listAssessmentsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listAssessmentQuestions = (variables) =>
  sendQueryRequest({
    query: variables?.last_evaluated_assessment
      ? listAssessmentQuestionsQueryByLastEvaluatedAssessmentQuestion
      : listAssessmentQuestionsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getAssessment = (variables) =>
  sendQueryRequest({
    query: getAssessmentQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createAssessment = (variables) =>
  sendMutationRequest({
    mutation: createAssessmentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateAssessment = (variables) =>
  sendMutationRequest({
    mutation: updateAssessmentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteAssessment = (variables) =>
  sendMutationRequest({
    mutation: deleteAssessmentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addQuestionsToAssessment = (variables) =>
  sendMutationRequest({
    mutation: addQuestionsToAssessmentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeQuestionsFromAssessment = (variables) =>
  sendMutationRequest({
    mutation: removeQuestionFromAssessmentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getEmployeeDashboardData = (variables) =>
  sendQueryRequest({
    query: getEmployeeDashboardDataQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const generateRoles = (variables) =>
  sendMutationRequest({
    mutation: generateRolesMut,
    variables: variables,
    fetchPolicy: "network-only",
  });


export const acknowledgeDocument = (variables) =>
  sendMutationRequest({
    mutation: acknowledgeDocumentMut,
    variables: variables,
    fetchPolicy: "network-only",
  });